import { get, post } from '@/unit/request.js'
export default {
    setXiansuo(data) {
        return post('xiansuo/setXiansuo', data)
    },
	GetList(data){
		return get('xiansuo/GetList',data)
	},
	//分配
	fenpei(data){
		return get('xiansuo/fenpei',data)
	},
	// 判断是否超级管理员
	panduanadm(data){
		return get('auth/panduanadm',data)
	},
	// 删除线索
	del(data){
		return post('xiansuo/delxiansuo',data)
	}

}