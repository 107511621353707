import { get, post } from '@/unit/request.js'
export default {
    //获取路由列表
    getRouters() {
        return get('auth/getRouters', {pid:1})
    },
    //获取顶级路由
    getTopRouter(){
        return get('auth/getTopRouter',{})
    },
    //保存数据
    setRoute(data){
        return post('auth/setRoute',data)
    },
	getchecks(data){
		 return post('echat/checks',data)
	}

}