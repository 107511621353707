import { get, post } from '@/unit/request.js'
export default {
	// 获取渠道
	getqudao(data){
		return post('qudao/getqudao',data)
	},
	//保存渠道
	setqudao(data){
		return post('qudao/setqudao',data)
	},
	// 删除
	del(data){
		return post('qudao/delqudao',data)
	}
	
}