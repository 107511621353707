import {
	get,
	post
} from '@/unit/request.js'
export default {
	//获取一个
	AdmUser(data) {
		return post('yeji/AdmUser', data)
	},

	addyeji(data) {
		return post('yeji/addyeji', data)
	},

	addyejiuser(data) {
		return post('yeji/setZonyeji', data)
	},
	
	getuseryeji(data) {
		return post('yeji/getuseryeji', data)
	},
	
	delyeji(data){
		return post('yeji/delyeji',data)
	}
}

