<template>
	<div id="app">
		<router-view> </router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
	}
</script>

<style>

	*{
			padding: 0;
			margin: 0;
			font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	 html,body {
		margin: 0;
		padding: 0;
		height: 100%;
	
	}
	::-webkit-scrollbar { 
	/* 	width:0px; 
		height: auto;
		background-color: brown; */
		
		/* 或者直接写下面的样式 */
		display:none;
	}

	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin: 0;
		padding: 0;
		height: 100%;
		width: 100%;
	}
</style>