import {get,post} from '@/unit/request.js'

export default {
	
	// 获取部门
	getgonsi(data){
		return post('gonsi/getGonsi',data)
	},
	//新增部门
	setgonsi(data){
		return post('gonsi/Setgonsi',data)
	},
	// 返回头部节点
	getgonsitop(data){
		return post('gonsi/getgonsitop',data)
	},
	// 修改节点
	updateGonsi(data){
		return post('gonsi/updateGonsi',data)
	},
	//删除节点
	del(data){
		return post('gonsi/del',data)
	},
	//获取头部节点，用户使用
	getgonsitoptouser(data){
		return post('gonsi/getgonsitoptouser',data)
	}
	
}