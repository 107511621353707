//用户路由
let route = [
    {	
		path:'user/list',//用户列表页面
		name:'用户列表',
		component:()=>import('@/view/user/userlist')

	},
    {	
		path:'user/gonsi',//用户所属部门
		name:'用户所属部门',
		component:()=>import('@/view/user/gonsi')

	},
	{
		path:'user/adminuser',//用户所属部门
		name:'管理员管理',
		component:()=>import('@/view/user/adminuser')
	
	},
	{
		path:'kehu/kehu',//用户所属部门
		name:'客户列表',
	
		component:()=>import('@/view/kehu/kehu')
	
	},
	{
		path:'/xiansuoadm',//用户所属部门
		name:'线索管理',
		component:()=>import('@/view/xiansuo/xiansuoadmin')
	
	},
	{
		path:'/xiansuo',//用户所属部门
		name:'线索列表',
		component:()=>import('@/view/xiansuo/xiansuo')
	
	},
	{
		path:'/renwuadm',//用户所属部门
		name:'线索列表',
		component:()=>import('@/view/renwu/renwuadmin')
	
	},
	{
		path:'/renwulist',//用户所属部门
		name:'线索列表',
		component:()=>import('@/view/renwu/renwulist')
	
	},
	{
		path:'/renwulistdaijie',//用户所属部门
		name:'待接任务',
		component:()=>import('@/view/renwu/renwulistdaijie')
	
	},
	{
		path:'/qudao',//用户所属部门
		name:'渠道',
		component:()=>import('@/view/qudaohangye/qudao')
	
	},
	{
		path:'/hangye',//用户所属部门
		name:'渠道',
		component:()=>import('@/view/qudaohangye/hangye')
	
	},
	{
		path:'/yejiadm',//用户所属部门
		name:'渠道',
		component:()=>import('@/view/yeji/yejiadm')
	
	},
	{
		path:'/kehubaobiao',//用户所属部门
		name:'渠道',
		component:()=>import('@/view/kehu/kehubaobiao')
	
	},
	{
		path:'/jiaoshi',//用户所属部门
		name:'教师',
		component:()=>import('@/view/jiaoshi/list')
	
	},
	{
		path:'/baoming',//用户所属部门
		name:'报名数据',
		component:()=>import('@/view/baoming/list')
	
	},
	{
		path:'/yem',//用户所属部门
		name:'页面设置',
		component:()=>import('@/view/baoming/indexpage')
	
	},
]

export default route