import {get,post} from '@/unit/request.js'

export default {
	getTest1(data) {
		return post('index/aaa', data)
	},
	getPostTest2(data) {
		return post('v1/getdatetime', data)
	},
	
	//获取教师
	
	getlist(data){
			
			return post('js/getlist',data)
		
	},
	// 添加教师
	addjs(data){
		
			return post('js/addjs',data)
	},
	//获取列表
	getbmlist(data){
		
		return post("bm/getlist",data)
	},
	deljs(data){
		return post("js/deljs",data)
	},
	// 导出报名
	daochubaoming(data){
		return post("bm/daochubaoming",data)
	},
	// 导出报名
	baocun(data){
		return post("bm/setup",data)
	},// 导出报名
	getdata(data){
		return post("bm/getup",data)
	}
}