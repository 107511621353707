let route=[
    {	
		path:'auth/index',//权限页面
		name:'权限',
		component:()=>import('@/view/auth/index')

	},
	{	
		path:'auth/route',//路由
		name:'权限',
		component:()=>import('@/view/auth/route')

	},
]
export default route