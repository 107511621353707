
let route = [
	{
		path: 'test',//默认首页
		name: 'tt',
		meta: {
			title: '总览',
		},

		component: () => import('@/view/index/test')
	},
	{
		path: 'tips',//无权限页面
		name: 'tips',
		meta: {
			title: '无权限页面',
		},

		component: () => import('@/view/tipsView')
	},
	
	
]


export default route