import Vue from 'vue'
import Router from 'vue-router'
//在subroute文件夹下声明路由文件，请遵守路由命名规则，"文件名/路由"

Vue.use(Router)

let newRouteS = []


const path = require('path')
let dir = "./subroute"

const ApiFileList = require.context('./subroute', true, /\.js$/)

let files = ApiFileList.keys()
files.forEach(paths => {

  let content = require(`${dir}/${path.join(paths)}`);
 
  content.default.forEach(element => {
    newRouteS.push(element)
  });
})

const routers = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login/login')
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: 'Page not found',
      isLogin: false
    },
    component: () => import('@/view/NotFound')
  },

  {
    path: '/',
    name: 'index',
    component: () => import('@/view/index/index'),
    children: newRouteS
  },
  {
    path: '*',
    redirect: '/404'
  }


]
console.log(routers)
const router = new Router({
  routes: routers
  /**  [
    {
      path: '/',
      name: 'HelloWorld',
      component: () => import('@/view/index/index')
    }
  ]*/
})

//解决路由冲突？
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


// // 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from代表从那个路径跳转而来
  // next 是一个函数，表示放行  next('/login) 强制跳转
  if (to.meta.title) { //如果设置标题，拦截后设置标题
  //  document.title = to.meta.title


  }

  if (to.path == '/login') return next()


  /*
  let pd = false

  
    pd = pathS[pat]
 
  if (!pd) 
  */


  const token = sessionStorage.getItem('token') ?? []
  if (!token) return next('/login')
  console.log('主页面路由', to.path)
  //  if(to.path!='/'){
  // console.log( '看看这是啥',next('/tips'))
  //   return 

  //  }



  next()
})


export default router 