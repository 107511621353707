import request from '@/unit/index.js'



export function get(url, data, isLogin=true, header = {}) {
	return request({
		url: url,
		method: 'get',
		params: data,
		header: header,
		isLogin: isLogin
	})

}
export function post(url, data,isLogin=true, header = {}) {
	return request({
		url: url,
		method: 'post',
		data: data,
		header: header,
		isLogin: isLogin
	})
}