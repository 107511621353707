import { get, post } from '@/unit/request.js'
export default {
	//管理员设置任务
    setrenwu(data) {
        return post('renwu/setRenwu', data)
    },
	//管理员的任务列表
	adminrenwulist(data){
		return get('renwu/adminrenwulist',data)
	},
	//普通业务员的任务列表
	getRenwu(data){
		return get('renwu/getRenwu',data)
	},
	//接取任务
	jiequrenwu(data){
		return get('renwu/jiequrenwu',data)
	},
	//接取任务
	daijiequrenwu(data){
		return get('renwu/daijiequrenwu',data)
	},
	//更新任务进度
	setOrent(data){
		return get('renwu/setOrent',data)
	}

}