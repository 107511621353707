import {get,post} from '@/unit/request.js'

export default {
	// 获取客户的列表
	getlist(data){
		return post("kehu/getkehulist",data)
	},
	// 添加客户
	addkehu(data){
		return post("kehu/addkehu",data)
	},
	upchengjiao(data){
		return post('kehu/upchengjiao',data)
	},
	// 新增回访记录
	addjilu(data){
		return post('kehu/addjilu',data)
	},
	//回访记录
	gethuifanglist(data){
		return post('kehu/gethuifanglist',data)
	},
	// 导出客户
	daochu(data){
		return post('kehu/daochu',data)
	},
	// 修改客户
	upkehu(data){
		return post('kehu/upkehu',data)
	},
	// 删除客户
	del(data){
		return post('kehu/delkehu',data)
	}
	
	
}