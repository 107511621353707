import {
	get,
	post
} from '@/unit/request.js'

export default {
	getTest(data) {
		return get('v1/getdatetime', data)
	},
	getPostTest(data) {
		return post('v1/getdatetime', data)
	},
	//保存公司
	setgonsi(data) {
		return post('user/setGonsi', data)
	},
	//地址搜索
	getGD(data) {
		return post('user/getGD', data)
	},
	//地址转字符串
	GPStoAddress(data) {
		return post('user/latlngToAddress', data)
	},
	// 修改用户可登陆状态
	updateuserstatus(data) {
		return post("user/updateuserstatus", data)
	},
	// 获取管理员列表
	GetAdminUser(data) {
		return post("user/GetAdminUser", data)
	},
	// 删除用户，管理员或普通业务员
	deleteuser(data) {
		return post('user/deletetUser', data)
	},
	// 获取部门列表
	getBumenList(data) {
		return post('user/getBumenList', data)
	},
	// 新增管理员
	setAdminUser(data) {
		return post('user/addAdminUser', data)
	},
	// 获取业务员列表
	GetLLUserList(data) {
		return post('user/GetLLUserList', data)
	},
	// 新增业务员
	setyUser(data) {
		return post('user/addyUser', data)
	},
	//转移资料
	zhuanyi(data) {
		return post('user/zhuanyi',data)

	},
	// 修改用户的所属部门
	upusergonsi(data){
			return post('user/upusergonsi',data)
	},
	// 获取公司id
	GetUserGonsi(data){
		return post('gonsi/GetUserGonsi',data)
	}
}